import { initAutocomplete } from '../../../libs/autocomplete';

(() => {
  `use strict`;

  //--------------------
  // Ready
  //--------------------

  document.addEventListener(`DOMContentLoaded`, () => {
    const search_selector = `.banner-with-search__wrapper`;
    const banner_search_container = document.querySelector(search_selector);

    // Autocomplete
    if (banner_search_container) {
      initAutocomplete(search_selector);
    }
  });
})();
