/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

/**
 * Conditionally run code for local environments.
 */
export const isDevelopment = () => {
  const local_server = `localhost:8080`;

  // Temporary hack
  if (window.location.search === `?force-dev`) {
    return true;
  }

  if (window.location.host.indexOf(local_server) !== -1) {
    return true;
  }

  return false;
};

/**
 * Convert nodeList to Array.
 * @param {NodeList} node_list NodeList from document query to convert.
 */
export const listToArray = (node_list) => {
  return Array.prototype.slice.call(node_list);
};

/**
 * Debounce function.
 * @param {Function} func Function to debounce.
 * @param {number} wait Time to wait before calling function.
 * @example debouncer(functionToRun, 200)
 */
export const debouncer = (func, wait_time) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait_time);
  };
};

/**
 * Check for current viewport.
 */
export const getViewport = () => {
  const viewport_element = document.querySelector('body');
  const element_styles = window.getComputedStyle(viewport_element, null);
  const viewport = parseInt(element_styles.zIndex);

  return viewport;
};

/**
 * Check for type.
 * @param {any} object The object to check.
 */
export const getType = (object) => {
  // Arrays
  if (Array.isArray(object)) {
    return 'array';
  }

  // String
  if (typeof object === 'string') {
    return 'string';
  }

  // Boolean
  if (typeof object === 'boolean') {
    return 'boolean';
  }

  // Undefined
  if (typeof object === 'undefined') {
    return 'undefined';
  }

  // Object
  return 'object';
};

/**
 * Send an XMLHttpRequest.
 * @param {Object} xhr_parameters Parameters for the request, including URL, data, and callbacks.
 * @param {string} method HTTP Request Method.
 */
export const sendXHR = (xhr_parameters, method) => {
  const { request_url, request_extras, request_success, request_failure } = xhr_parameters;

  // Create XHR
  const xhr = new XMLHttpRequest();

  /* eslint-disable no-console */
  // Handle timeouts in modern browsers
  xhr.ontimeout = () => {
    console.log(`FAIL - HTTP request for ${request_url} timed out.`);
  };

  // Handle aborted connections
  xhr.onabort = () => {
    console.log(`FAIL - HTTP request for ${request_url} was aborted.`);
  };
  /* eslint-enable */

  // Handle when XHR has processed
  xhr.onreadystatechange = (request) => {
    const result = request.target;

    if (result.readyState === 4) {
      switch (result.status) {
        case 200:
        case 201:
        case 204:
          request_success(result.responseText, request_extras);
          break;
        default:
          request_failure(result);
          break;
      }
    }
  };

  // Start the XHR process
  xhr.open(method, request_url, true);
  xhr.timeout = 120000;
  xhr.withCredentials = true;

  let timeout = 0;

  if (isDevelopment()) {
    // Simulate a real-world environment
    timeout = Math.ceil(Math.random() * 2000);
  }

  setTimeout(() => {
    switch (method) {
      case 'GET':
        xhr.send();
        break;
      case 'POST':
        // This is the request payload
        xhr.send(request_extras);
        break;
      // no default
    }
  }, timeout);
};

/**
 * Default failure for XHR call.
 */
export const defaultFailedRequest = () => {
  // eslint-disable-next-line no-console
  console.log(`The request failed.`);
};

/**
 * Wrap bold tags around some of the characters in a string.
 * @param {string} partial The string partial to be bolded.
 * @param {string} string_to_change The string that will be transformed.
 */
export const getBoldText = (partial, string_to_change) => {
  const partial_index = string_to_change.indexOf(partial.toLowerCase());
  const target_string = string_to_change.substr(partial_index, partial.length);

  return string_to_change.replace(target_string, `<b>${target_string}</b>`);
};
