import { debouncer, getViewport, listToArray } from '../../../libs/utils';

(() => {
  'use strict';

  //--------------------
  // Functions
  //--------------------

  /**
   * Remove mobile menu on resize.
   */
  const watchToolbarMenu = debouncer(() => {
    const viewport = getViewport();

    if (viewport === 3) {
      closeMainMenu();
    }
  }, 100);

  /**
   * Toggle the display of a main menu submenu.
   * @param {Element} element The element that controls the submenu.
   */
  const toggleMenuDropdown = (element) => {
    const target_container = element.closest(`.main-menu__dropdown-container`);

    target_container.classList.toggle('active');
  };

  /**
   * Remove mobile menu on key press.
   * @param {Event} event The event.
   */
  const listenForEsc = (event) => {
    const key_code = event.keyCode;

    // ESC
    if (key_code === 27) {
      closeMainMenu();
      return;
    }
  };

  /**
   * Close the toolbar main menu.
   */
  const closeMainMenu = () => {
    const menu_container = document.querySelector(`.toolbar__navigation`);
    menu_container.classList.remove('active');
    document.querySelector(`body`).classList.remove('active');
  };

  /**
   * Close all of the toolbar main menu submenus.
   */
  const closeAllMainMenuSubMenus = () => {
    const menu_submenu_list = listToArray(document.querySelectorAll(`.main-menu__dropdown-container`));

    menu_submenu_list.forEach((submenu) => {
      submenu.classList.remove('active');
    });
  };

  /**
   * Highlight active menu item based on site IA and window location.
   */
  const highlightActiveMenuItem = (toolbar_menu) => {
    const current_href = window.location.href.split('?')[0];
    const all_menu_items = listToArray(toolbar_menu.querySelectorAll(`a.main-menu__item`));

    all_menu_items.forEach((menu_item) => {
      const item_destination = menu_item.getAttribute('href');

      if (current_href.indexOf(item_destination) !== -1) {
        menu_item.classList.add('active');
      }
    });
  };

  //--------------------
  // Ready
  //--------------------

  document.addEventListener('DOMContentLoaded', () => {
    const toolbar_menu = document.querySelector(`.toolbar__navigation--menu`);
    const menu_dropdown_control = document.querySelector(`.main-menu__dropdown-container`);

    if (toolbar_menu) {
      highlightActiveMenuItem(toolbar_menu);
      toolbar_menu.addEventListener('click', (event) => {
        if (event.target.closest(`.toolbar__navigation--underlay`)) {
          closeMainMenu();
        }

        return;
      });
    }

    if (menu_dropdown_control) {
      menu_dropdown_control.addEventListener('click', (event) => {
        const close_button = event.target.closest(`.main-menu__dropdown-control button`);

        if (close_button) {
          toggleMenuDropdown(close_button);
        } else {
          closeAllMainMenuSubMenus();
        }

        return;
      });
    }

    // Events
    document.addEventListener('click', (event) => {
      if (!menu_dropdown_control.contains(event.target)) {
        closeAllMainMenuSubMenus();
      }

      return;
    });

    window.addEventListener(`resize`, watchToolbarMenu);
    document.addEventListener('keydown', listenForEsc);
  });
})();
