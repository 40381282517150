import { debouncer, getViewport } from '../../../libs/utils';

(() => {
  'use strict';

  //--------------------
  // Functions
  //--------------------

  /**
   * Toggle the display of the search filter panel.
   * @param {Element} element The element that controls the submenu.
   */
  const toggleSearchFilterPanel = () => {
    const filter_panel_container = document.querySelector(`.search-filters__panel`);
    filter_panel_container.classList.toggle('active');
  };

  /**
   * Close the search filter panel.
   */
  const closeSearchFilterPanel = () => {
    const filter_panel_container = document.querySelector(`.search-filters__panel`);
    filter_panel_container.classList.remove('active');
  };

  /**
   * Remove the search filter panel on key press.
   * @param {Event} event The event.
   */
  const listenForEsc = (event) => {
    const key_code = event.keyCode;

    // ESC
    if (key_code === 27) {
      closeSearchFilterPanel();
      return;
    }
  };

  /**
   * Adjust minimum height of page to handle absolute filters on resize.
   */
  const adjustPageHeight = debouncer(() => {
    const search_results_container = document.querySelector(`.search-help__results`);
    const viewport = getViewport();

    if (viewport !== 1) {
      const search_filters_panel = document.querySelector(`.search-filters__panel`);
      const panel_height = search_filters_panel.offsetHeight;
      const extra_spacing = 40;

      const new_height = panel_height + extra_spacing;
      search_results_container.style.minHeight = `${new_height}px`;
    } else {
      search_results_container.style.removeProperty('min-height');
    }
  }, 100);

  /**
   * Reload the page with new num_ranks value.
   * @param {Event} event The event.
   */
  const adjustNumRanks = (event) => {
    const select_value = event.target.value;
    const current_location = window.location.href;
    const location_split = current_location.split(`?`);
    let new_location = location_split[0];

    // A query has been run
    if (location_split.length === 2) {
      const query_split = location_split[1].split(`&`);

      const new_query = query_split
        .map((item) => {
          // Set new num_ranks
          if (item.indexOf(`num_ranks`) === 0) {
            return `num_ranks=${select_value}`;
          }

          // Reset start_rank
          if (item.indexOf(`start_rank`) === 0) {
            return `start_rank=1`;
          }

          return item;
        })
        .join(`&`);

      new_location += `?${new_query}`;
    } else {
      new_location += `?num_ranks=${select_value}`;
    }

    window.location = new_location;
  };

  /**
   * Set default per page option with num_ranks value.
   * @param {Element} select_field The select field.
   */
  const setDefaultNumRanks = (select_field) => {
    const current_query = window.location.search;

    if (current_query !== ``) {
      const has_num_ranks = current_query.match(/num_ranks=(\d+)/);

      if (has_num_ranks) {
        const set_option = has_num_ranks[1];

        // Check whether the option is valid
        const is_valid =
          Array.from(select_field.options).filter((option) => {
            return option.value === set_option;
          }).length > 0;

        if (is_valid) {
          select_field.value = set_option;
        }
      }
    }
  };

  //--------------------
  // Ready
  //--------------------

  document.addEventListener('DOMContentLoaded', () => {
    const search_filter_enablement = document.querySelector(`.search-filters__enablement`);
    const search_filter_panel_header = document.querySelector(`.filter-panel__header`);
    const search_results_container = document.querySelector(`.search-help__results`);
    const num_ranks_selector = document.querySelector(`.results-per-page__options`);

    if (search_filter_enablement || search_filter_panel_header) {
      document.addEventListener('keydown', listenForEsc);
    }

    if (search_filter_enablement) {
      search_filter_enablement.addEventListener('click', (event) => {
        if (event.target.closest(`.search-filters__control`)) {
          toggleSearchFilterPanel();
        }

        return;
      });
    }

    if (search_filter_panel_header) {
      search_filter_panel_header.addEventListener('click', (event) => {
        if (event.target.closest(`.filter-panel__header`)) {
          toggleSearchFilterPanel();
        }

        return;
      });
    }

    if (search_results_container) {
      adjustPageHeight();
      window.addEventListener(`resize`, adjustPageHeight);
    }

    if (num_ranks_selector) {
      const select_field = num_ranks_selector.querySelector(`select`);

      setDefaultNumRanks(select_field);
      select_field.onchange = (event) => {
        adjustNumRanks(event);
      };
    }
  });
})();
