/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { debouncer, defaultFailedRequest, getBoldText, isDevelopment, listToArray, sendXHR } from './utils';

/**
 * Main initializer for Funnelback autocomplete.
 * @param {string} container_selector The query selector for the autocomplete container.
 */
export const initAutocomplete = (container_selector) => {
  const search_container = document.querySelector(container_selector);
  const search_input = search_container.querySelector(`input[data-autocomplete]`);

  const suggest_target = search_input.getAttribute(`data-autocomplete`);
  const suggest_container = document.querySelector(`#${suggest_target}`);

  // Events
  document.addEventListener('click', (event) => {
    // Use activeElement so pressing Enter in the input won't
    // activate a click on the first autocomplete item's button
    if (suggest_container.contains(document.activeElement)) {
      submitSuggestion(search_input, event);
    } else {
      hideAutocomplete(search_input);
    }

    return;
  });

  search_input.addEventListener('blur', handleBlur);
  search_input.addEventListener('focus', handleFocus);
  search_input.addEventListener('keydown', handleInputKeydown);
  suggest_container.addEventListener('keydown', handleSuggestionsKeydown);
};

//--------------------
// Functions
//--------------------

/**
 * Get the target autocomplete container by event.
 * @param {Event} event The event that occurs.
 */
const getAutocompleteByEvent = (event) => {
  const event_target = event.target;

  if (event_target) {
    getAutocompleteByInputField(event_target);
  }

  return null;
};

/**
 * Get the target autocomplete container by input field.
 * @param {Element} target_input The search input element.
 */
const getAutocompleteByInputField = (target_input) => {
  const autocomplete_target = target_input.getAttribute(`data-autocomplete`);

  if (autocomplete_target) {
    return document.querySelector(`#${autocomplete_target}`);
  }

  return null;
};

/**
 * Toggle the display of the autocomplete.
 * @param {string} type The type of display to action.
 * @param {Event} event The event that occurs.
 */
const toggleAutocomplete = (type, event) => {
  const container = getAutocompleteByEvent(event);

  if (type === `show`) {
    container.classList.add(`active`);
  } else {
    const all_containers = listToArray(document.querySelectorAll(`.autocomplete-container`));
    all_containers.forEach((element) => {
      element.classList.remove(`active`);
    });
  }
};

/**
 * Hide the autocomplete.
 * @param {Element} target_input The search input element.
 */
const hideAutocomplete = (target_input) => {
  const container = getAutocompleteByInputField(target_input);

  container.classList.remove(`active`);
};

/**
 * Check for search suggestions.
 * @param {string} input_value The search input value.
 * @param {Element} target_input The search input element.
 */
const checkForSuggestions = (input_value, target_input) => {
  // Only when three characters are entered
  if (input_value.length >= 3) {
    getSuggestions(input_value, target_input);
  } else {
    // Clear and hide if suggestions were present
    clearSuggestions(target_input);
  }
};

/**
 * Clear search suggestions.
 * @param {Element} target_input The search input element.
 */
const clearSuggestions = (target_input) => {
  const suggest_target = target_input.getAttribute(`data-autocomplete`);
  const suggest_container = document.querySelector(`#${suggest_target}`);

  suggest_container.innerHTML = ``;
  suggest_container.classList.remove(`active`);
};

/**
 * Get suggestion keywords.
 * @param {string} input_value The search input value.
 * @param {Element} target_input The search input element.
 */
const getSuggestions = (input_value, target_input) => {
  const suggestions_url = target_input.getAttribute('data-suggestions');
  const encoded_query = encodeURIComponent(input_value);
  let request_url = `${suggestions_url}?partial_query=${encoded_query}`;

  if (isDevelopment()) {
    request_url = `./externals/simple-suggest.json`;
  }

  const xhr_parameters = {
    request_url,
    request_success: (response) => {
      processSuggestions(response, target_input);
    },
    request_failure: defaultFailedRequest,
    request_extras: ``,
  };

  sendXHR(xhr_parameters, 'GET');
};

/**
 * Process suggestions.
 * @param {string} suggestions The search suggestions in string format.
 * @param {Element} target_input The search input element.
 */
const processSuggestions = (suggestions, target_input) => {
  const all_suggestions = JSON.parse(suggestions);
  const input_value = target_input.value;
  const suggest_target = target_input.getAttribute(`data-autocomplete`);
  const suggest_container = document.querySelector(`#${suggest_target}`);
  const maximum = 4;

  if (all_suggestions.length > 0) {
    const shown_suggestions = all_suggestions.slice(0, maximum);

    // Format the list items
    const getItems = (list_items) => {
      return list_items.map((suggestion, index) => {
        const raw_suggestion = suggestion.disp;
        const formatted_suggestion = getBoldText(input_value, raw_suggestion);

        return `
          <li>
            <button class="autocomplete-results__item" data-index="${index}" data-raw="${raw_suggestion}" tabindex="-1">${formatted_suggestion}</button>
          </li>
        `;
      });
    };

    // Remove comma separator
    const list_items = getItems(shown_suggestions).join('');

    // Format the suggestions container
    suggest_container.innerHTML = `
      <ul class="autocomplete-results__list">
        ${list_items}
      </ul>
    `;

    // Reveal the container
    suggest_container.classList.add(`active`);
  } else {
    // Clear and hide the suggestions container
    clearSuggestions(target_input);
  }
};

/**
 * Process suggestions.
 * @param {Element} search_input The search input element.
 * @param {Event} event The event that occurs.
 */
const submitSuggestion = (search_input, event) => {
  event.preventDefault();

  const target_button = event.target;
  const raw_suggestion = target_button.getAttribute(`data-raw`);

  if (raw_suggestion) {
    search_input.value = raw_suggestion;
    hideAutocomplete(search_input);

    const search_form = search_input.closest(`form`);
    search_form.submit();
  }
};

/**
 * Keyboard navigation for suggestions.
 * @param {Element} target_input The search input element.
 * @param {number} key_code The key that has been pressed.
 */
const traverseSuggestions = (target_input, key_code) => {
  const suggest_target = target_input.getAttribute(`data-autocomplete`);
  const suggest_container = document.querySelector(`#${suggest_target}`);
  const suggest_class = `autocomplete-results__item`;
  const suggest_items = listToArray(suggest_container.querySelectorAll(`button.${suggest_class}`));
  const active_element = document.activeElement;
  const suggestion_focused = active_element.classList.contains(suggest_class);

  if (suggest_items.length > 0) {
    if (!suggestion_focused) {
      // First time going into suggestions, expect the Arrow Down key
      if (key_code === `ArrowDown`) {
        suggest_items[0].focus();
      }
    } else {
      const last_index = suggest_items.length - 1;
      const item_index = parseInt(active_element.getAttribute(`data-index`));

      if (key_code === `ArrowDown`) {
        const target_index = item_index + 1;

        if (target_index > last_index) {
          suggest_items[0].focus();
        } else {
          suggest_items[target_index].focus();
        }
      }

      if (key_code === `ArrowUp`) {
        const target_index = item_index - 1;

        if (target_index < 0) {
          target_input.focus();
          hideAutocomplete(target_input);
        } else {
          suggest_items[target_index].focus();
        }
      }
    }
  }
};

/**
 * Handle clicking out of the input field.
 * @param {Event} event The event.
 */
const handleBlur = (event) => {
  const suggest_class = `autocomplete-results__item`;
  const next_active = event.relatedTarget;

  // Only close the autocomplete if we're not traversing the suggestions
  if (next_active) {
    if (!next_active.classList.contains(suggest_class)) {
      toggleAutocomplete(`hide`, event);
    }
  }
};

/**
 * Handle clicking into the input field.
 * @param {Event} event The event.
 */
const handleFocus = (event) => {
  const target_input = event.target;
  const input_value = target_input.value;

  if (input_value !== '') {
    toggleAutocomplete(`show`, event);
    checkForSuggestions(input_value, target_input);
  }
};

/**
 * Handle keyboard interaction in search input.
 * @param {Event} event The event.
 */
const handleInputKeydown = debouncer((event) => {
  const key_code = event.code;
  const target_input = event.target;
  const input_value = target_input.value;

  if (key_code === `Escape`) {
    hideAutocomplete(target_input);
    return;
  }

  if (input_value !== '') {
    if (key_code === `ArrowDown`) {
      traverseSuggestions(target_input, key_code);
    } else {
      checkForSuggestions(input_value, target_input);
    }
  } else {
    // Clear and hide the suggestions container
    clearSuggestions(target_input);
  }
}, 200);

/**
 * Handle keyboard interaction in search suggestions.
 * @param {Event} event The event.
 */
const handleSuggestionsKeydown = (event) => {
  const key_code = event.code;

  if (key_code === `Escape`) {
    toggleAutocomplete(`hide`, event);
    return;
  }

  if (key_code === `ArrowDown` || key_code === `ArrowUp`) {
    event.preventDefault();
    const target_input_id = event.currentTarget.getAttribute(`data-source`);
    const target_input = document.querySelector(`#${target_input_id}`);

    traverseSuggestions(target_input, key_code);
  }
};
