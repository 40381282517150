(() => {
  'use strict';

  //--------------------
  // Functions
  //--------------------

  /**
   * Remove dropdown menu on key press.
   * @param {Event} event The event.
   */
  const listenForKeys = (event) => {
    const key_code = event.key;

    // Return
    if (key_code === `Enter`) {
      event.preventDefault();
      return;
    }

    // ESC
    if (key_code === `Escape`) {
      closeDropdown();
      return;
    }
  };

  /**
   * Toggle the display of the dropdown menu.
   */
  const toggleDropdown = () => {
    const dropdown_menu = document.querySelector(`.dropdown-menu`);
    dropdown_menu.classList.toggle('active');
  };

  /**
   * Close the dropdown menu.
   */
  const closeDropdown = () => {
    const dropdown_menu = document.querySelector(`.dropdown-menu`);
    dropdown_menu.classList.remove('active');
  };

  //--------------------
  // Ready
  //--------------------

  document.addEventListener('DOMContentLoaded', () => {
    const dropdown_button = document.querySelector(`.dropdown-button`);

    if (dropdown_button) {
      dropdown_button.addEventListener('click', (event) => {
        event.preventDefault();

        if (event.target.closest(`.dropdown-container`)) {
          toggleDropdown();
        }

        return;
      });

      // Events
      document.addEventListener('click', (event) => {
        if (!dropdown_button.contains(event.target)) {
          closeDropdown();
        }

        return;
      });

      document.addEventListener('keydown', listenForKeys);
    }
  });
})();
