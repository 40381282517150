(() => {
  'use strict';

  //--------------------
  // Functions
  //--------------------

  /**
   * Close the left navigation menu.
   */
  const closeLeftNavMenu = () => {
    const menu_container = document.querySelector(`.left-sidenav__control`);
    menu_container.classList.remove('active');
  };

  /**
   * Remove mobile menu on key press.
   * @param {Event} event The event.
   */
  const listenForEsc = (event) => {
    const key_code = event.keyCode;

    // ESC
    if (key_code === 27) {
      closeLeftNavMenu();
      return;
    }
  };

  //--------------------
  // Ready
  //--------------------

  document.addEventListener('DOMContentLoaded', () => {
    const menu_open_button = document.querySelector(`.content-header__control`);
    const menu_close_button = document.querySelector(`.left-sidenav__control`);
    const menu_container = document.querySelector(`.left-sidenav`);

    if (menu_open_button) {
      menu_open_button.addEventListener('click', () => {
        menu_container.classList.add('active');
        return;
      });
    }

    if (menu_close_button) {
      menu_close_button.addEventListener('click', () => {
        menu_container.classList.remove('active');
        return;
      });
    }

    // Events
    document.addEventListener('keydown', listenForEsc);
  });
})();
