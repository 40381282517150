(() => {
  'use strict';

  //--------------------
  // Functions
  //--------------------

  /**
   * Toggle the display of the toolbar main menu.
   */
  const toggleMainMenu = () => {
    const menu_container = document.querySelector(`.toolbar__navigation`);
    menu_container.classList.toggle('active');
    document.querySelector(`body`).classList.toggle('active');
  };

  //--------------------
  // Ready
  //--------------------

  document.addEventListener('DOMContentLoaded', () => {
    const mobile_menu_toggler = document.querySelector(`.toolbar__navigation--controller`);
    const mobile_menu_header = document.querySelector(`.toolbar__navigation-header`);

    if (mobile_menu_toggler && mobile_menu_header) {
      // Menu button (Mobile, Tablet)
      mobile_menu_toggler.addEventListener('click', (event) => {
        if (event.target.closest(`.header-nav__control`)) {
          toggleMainMenu();
        }

        return;
      });

      // Menu button (Mobile)
      mobile_menu_header.addEventListener('click', (event) => {
        if (event.target.closest(`.header-nav__control`)) {
          toggleMainMenu();
        }

        return;
      });
    }
  });
})();
