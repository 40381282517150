import { initAutocomplete } from '../../../libs/autocomplete';
import { debouncer, getViewport } from '../../../libs/utils';

(() => {
  `use strict`;

  //--------------------
  // Functions
  //--------------------

  /**
   * Toggle the display of the toolbar site search.
   */
  const toggleSearch = () => {
    document.querySelector(`body`).classList.remove('active');
    const search_container = document.querySelector(`.toolbar__search-container`);
    search_container.classList.toggle(`active`);

    // Hide mobile menu
    const menu_container = document.querySelector(`.toolbar__navigation`);
    menu_container.classList.remove(`active`);

    // Focus in to newly revealed search input
    if (search_container.classList.contains(`active`)) {
      search_container.querySelector(`input[type="text"]`).focus();
    }
  };

  /**
   * Remove mobile behaviour on resize.
   */
  const watchSearch = debouncer(() => {
    const viewport = getViewport();

    if (viewport === 3) {
      document.querySelector(`.toolbar__search-container`).classList.remove(`active`);
    }
  }, 100);

  /**
   * Handle keyboard interaction in search input.
   * @param {Event} event The event.
   */
  const handleInputKeydown = (event) => {
    const key_code = event.keyCode;

    // ESC
    if (key_code === 27) {
      toggleSearch();
      return;
    }
  };

  //--------------------
  // Ready
  //--------------------

  document.addEventListener(`DOMContentLoaded`, () => {
    const toolbar_search = document.querySelector(`.toolbar__search`);

    if (toolbar_search) {
      toolbar_search.addEventListener(`click`, (event) => {
        if (event.target.closest(`.toolbar__search-control`)) {
          toggleSearch();
        }

        return;
      });

      // Autocomplete
      const search_selector = `.toolbar__search-container`;
      initAutocomplete(search_selector);

      const search_container = document.querySelector(search_selector);
      const search_input = search_container.querySelector(`input[data-autocomplete]`);

      // Events
      window.addEventListener(`resize`, watchSearch);
      search_input.addEventListener('keydown', handleInputKeydown);
    }
  });
})();
