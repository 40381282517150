/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { debouncer, defaultFailedRequest, listToArray, sendXHR } from '../../../libs/utils';

(() => {
  'use strict';

  let integrations_store = [];
  let query = ``;
  let category = `all-integrations`;
  let sector = `all`;

  //--------------------
  // Functions
  //--------------------

  /**
   * Handle keyboard interaction in search input.
   * @param {Event} event The event.
   */
  const handleInputKeydown = debouncer((event) => {
    const target_input = event.target;
    query = target_input.value;

    filterIntegrations();
  }, 200);

  /**
   * Get all integrations.
   */
  const getIntegrations = () => {
    const container = document.querySelector(`.data-listing__container`);
    const integrations_url = container.getAttribute('data-source');

    // Check URL first
    checkForQuery();

    const xhr_parameters = {
      request_url: integrations_url,
      request_success: (response) => {
        processIntegrations(response);
      },
      request_failure: defaultFailedRequest,
      request_extras: ``,
    };

    sendXHR(xhr_parameters, 'GET');
  };

  /**
   * Process integrations.
   * @param {string} integrations The search suggestions in string format.
   */
  const processIntegrations = (integrations) => {
    const all_integrations = JSON.parse(integrations);
    const results_container = document.querySelector(`.data-list__results`);

    if (all_integrations.length > 0) {
      // Store the results
      integrations_store = all_integrations;
      filterIntegrations();
    } else {
      // Clear the integrations result container
      results_container.innerHTML = ``;
    }
  };

  /**
   * Filter the integrations.
   * @param {string} integrations The search suggestions in string format.
   */
  const filterIntegrations = () => {
    let all_integrations = [...integrations_store];
    const results_container = document.querySelector(`.data-list__results`);

    // Filter by category
    if (category !== 'all-integrations') {
      all_integrations = all_integrations.filter((item) => item.category.split(`; `).indexOf(category) !== -1);
    }

    // Filter by sector
    if (sector !== 'all') {
      all_integrations = all_integrations.filter((item) => item.sector.split(`; `).indexOf(sector) !== -1);
    }

    // Filter by query
    if (query !== '') {
      const data_query = query.toLowerCase().replace(/ /g, `-`);
      all_integrations = all_integrations.filter((item) => item.key.indexOf(data_query) !== -1);
    }

    // Format the list items
    const getItems = (list_items) => {
      return list_items.map((integration) => {
        return `
        <li class="data-list-results__item">
          <a href="${integration.link}" class="integration-card__link">
            <div class="integration-card__logo">
              <img alt="Logo for ${integration.name}" src="${integration.logo}" />
            </div>
            <div class="integration-card__body">
              <span class="integration-card__heading">${integration.name}</span>
              <span class="integration-card__content">${integration.description}</span>
            </div>
          </a>
        </li>
      `;
      });
    };

    // Remove comma separator
    const list_items = getItems(all_integrations).join('');

    // Add results
    results_container.innerHTML = `
      <ul class="data-list-results__list">
        ${list_items}
      </ul>
    `;
  };

  /**
   * Check URL for query.
   */
  const checkForQuery = () => {
    const query_string = window.location.search.split(`?`)[1];

    if (query_string) {
      const queries = query_string.split(`&`);

      // Look for category
      const chosen_category = queries.filter((query) => query.split(`=`)[0] === `category`);

      if (chosen_category.length > 0) {
        const query_category = chosen_category[0].split(`=`)[1];
        category = query_category;

        // Update display
        updatePageDisplay(query_category);
      }
    }
  };

  /**
   * Update display of page.
   * @param {string} category_id The id for the chosen category.
   */
  const updatePageDisplay = (category_id) => {
    const category_menu_item = document.querySelector(`.left-sidenav-item[data-category="${category_id}"]`);

    if (category_menu_item) {
      const category_name = category_menu_item.innerText;

      // Headings
      document.querySelector(`.data-listing__header h1`).innerText = category_name;
      document.querySelector(`.content-header h2`).innerText = category_name;

      // Side navigation
      document.querySelector(`.left-sidenav-item.active`).classList.remove(`active`);
      category_menu_item.classList.add(`active`);
      document.querySelector(`.left-sidenav`).classList.remove(`active`);
    }
  };

  //--------------------
  // Ready
  //--------------------

  document.addEventListener('DOMContentLoaded', () => {
    const datalist_form = document.querySelector(`.data-list__form`);
    const details_page = document.querySelector(`[data-page="integration-details"]`);

    if (datalist_form || details_page) {
      if (datalist_form) {
        const filter_input = datalist_form.querySelector(`#data-list-query`);

        // Call endpoint for integrations
        getIntegrations();

        // Set up behaviour for sector dropdown
        listToArray(document.querySelectorAll('.dropdown-menu__item')).forEach((item) => {
          item.addEventListener('click', (event) => {
            event.preventDefault();
            const target_button = event.target.closest(`.dropdown-menu__item`);
            const sector_friendly = target_button.innerHTML;

            document.querySelector(`[data-id="dropdown-selection"]`).innerHTML = sector_friendly;

            // Update filters
            sector = target_button.getAttribute(`data-filter`);
            filterIntegrations();
          });
        });

        // Events
        filter_input.addEventListener('keydown', handleInputKeydown);
      }

      // Set up behaviour for left-navigation categories
      listToArray(document.querySelectorAll('button.left-sidenav-link')).forEach((item) => {
        item.addEventListener('click', (event) => {
          event.preventDefault();
          const target_button = event.target.closest(`.left-sidenav-link`);
          const category_id = target_button.innerText.toLowerCase().replace(/ /g, `-`);

          if (details_page) {
            const root_page = details_page.getAttribute(`data-root`);

            // Make the button clickable from the details page
            window.location = `${root_page}?category=${category_id}`;
          } else {
            // Update display
            updatePageDisplay(category_id);

            // Update filters
            category = category_id;
            filterIntegrations();
          }
        });
      });
    }
  });
})();
